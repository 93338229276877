<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">
              {{ 'Register as volunteer'|trans }}
            </h5>
          </div>

          <div class="card-padding">
            <v-row v-if="localAlert">
              <v-col cols="12">
                <v-alert :type="localAlert.type" :color="localAlert.color">
                  {{ localAlert.message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="validationErrors">
              <v-col cols="12" v-for="validationError in validationErrors">
                <v-alert
                  :type="validationError.type"
                  :color="validationError.color"
                >
                  {{ validationError.message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-form v-model="valid" ref="form">
              <v-text-field
                hide-details
                :rules="validation.required"
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                :placeholder="'Full Name'|trans"
                v-model="volunteer.name"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                  required
                "
              >
              </v-text-field>
              <v-text-field
                hide-details
                :rules="validation.required"
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                :placeholder="'Email'|trans"
                v-model="volunteer.email"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              >
              </v-text-field>

              <AvailableUserCategories
                  v-model="volunteer.category"
                  classList="
                      input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
"
              ></AvailableUserCategories>

              <v-text-field
                hide-details
                :rules="validation.required"
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                :placeholder="'Graduation year'|trans"
                v-model="volunteer.yearOfStudy"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                  required
                "
              >
              </v-text-field>

              <AvailableServices
                v-model="volunteer.services"
                classList="
                      input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
"
              ></AvailableServices>

              <v-combobox
                :items="availableLanguages"
                :placeholder="'Spoken languages'|trans"
                :rules="validation.required"
                item-text="name"
                item-value="code"
                multiple
                clearable
                :return-object="true"
                v-model="volunteer.spokenLanguages"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
                outlined
                hide-details
                light
                dense
              >
              </v-combobox>

              <v-text-field
                type="password"
                :rules="validation.required"
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                :placeholder="'Password'|trans"
                v-model="volunteer.newPassword"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              >
              </v-text-field>
              <v-text-field
                type="password"
                :rules="validation.required"
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                :placeholder="'Repeat password'|trans"
                v-model="volunteer.newPasswordRepeat"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
              >
              </v-text-field>

              <vue-recaptcha
                :loadRecaptchaScript="recaptchaRequired"
                ref="recaptcha"
                v-if="recaptchaRequired"
                :sitekey="recaptchaSiteKey"
                @verify="captchaVerify"
              ></vue-recaptcha>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="#5e72e4"
                small
                @click="handleRegister"
                :loading="isLoading"
                >{{ 'Register'|trans }}
              </v-btn>
              <p class="text-sm text-body mt-3 mb-0">
                {{ 'Already have an account ?'|trans }}
                <router-link
                  :to="{ name: 'Login' }"
                  class="
                    text-default text-gradient-default text-decoration-none
                    font-weight-bold
                  "
                >
                  {{ 'Sign in'|trans }}
                </router-link>
              </p>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import availableLanguages from "@/data/languages.json";
import AvailableServices from "@/components/AvailableServices";
import VueRecaptcha from "vue-recaptcha";
import AvailableUserCategories from "@/components/AvailableUserCategories";

export default {
  name: "login",
  components: {
    AvailableUserCategories,
    AvailableServices,
    VueRecaptcha,
  },
  data() {
    return {
      valid: false,
      localAlert: null,
      validationErrors: [],
      isLoading: false,
      validation: {
        required: [(v) => !!v || "Is required"],
      },
      volunteer: {
        name: "",
        email: "",
        newPassword: "",
        newPasswordRepeat: "",
        spokenLanguages: [],
        services: [],
        category: null,
        categoryGuid: null,
        yearOfStudy: "",
        identityID: "",
        captcha: null,
      },
      availableLanguages: availableLanguages,
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaRequired: process.env.NODE_ENV === "production",
    };
  },
  computed: {
    passwordsMatch() {
      return this.volunteer.newPassword === this.volunteer.newPasswordRepeat;
    },
  },
  methods: {
    captchaVerify: function (response) {
      this.volunteer.captcha = response;
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset();
      }

      return this.$refs.form.reset();
    },
    handleRegister(e) {
      e.preventDefault();
      this.localAlert = null;
      this.validationErrors = [];

      if (false === this.validate()) {
        //error
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Please check the form below for errors!"),
        };
        return false;
      }

      if (!this.passwordsMatch) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Passwords must match"),
        };
        return false;
      }

      this.isLoading = true;

      let data = this._.cloneDeep(this.volunteer);

      data.services = data.services.map(function (service) {
        return service.guid;
      });
      data.categoryGuid = data.category.guid;
      data.spokenLanguages = data.spokenLanguages.map(function (
        spokenLanguage
      ) {
        return spokenLanguage.code;
      });
      let $this = this;
      fetch(process.env.VUE_APP_API_URL + "/register/volunteer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.$store.getters.getLocale,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message ? data.message : this.$translator.trans("Registration failed!"),
            };

            if (typeof data.errors !== "undefined") {
              for (let i = 0; i < data.errors.length; i++) {
                let error = data.errors[i];
                $this.validationErrors.push({
                  type: "error",
                  color: "red",
                  message: Object.values(error)[0],
                });
              }
            }

            if (this.$refs.recaptcha) {
              this.$refs.recaptcha.reset();
            }

            return false;
          }

          this.localAlert = {
            type: "success",
            color: "green",
            message: this.$translator.trans("Registration successful! Check your email!"),
          };

          this.reset();
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message ? error.message : this.$translator.trans("Registration failed!"),
          };
        });

      this.isLoading = false;
      this.validationErrors = [];
    },
  },
};
</script>
